import Button, {ButtonShape, ButtonSize, ButtonVariant} from "@components/Button";
import {getButtonStyle} from "@components/Button/styles";
import {useTranslation} from "@i18n/client";
import React, {memo, useCallback, useMemo} from "react";
import {v5Pages} from "src/components/_common/_constants";
import {Lookup} from "src/store/types";
import {analytics} from "src/utils/analytics";
import {GetOpenTimeResult} from "src/utils/timeUtils";

import {SoonestSlotTimeByLocationAndSpecialty} from "../../../hooks/useGetSoonestTime";
import SpecialtyAndTime, {SpecialtyForSpecialtyAndTime} from "./SpecialtyAndTime";
import useNextPagesPaths from "src/hooks/app-pages-transition/useNextPagesPaths";
import {createURLBuilder} from "src/utils/urls";

type SpecialtyForLocationDetailsCard = SpecialtyForSpecialtyAndTime & {
  isVirtual?: boolean;
  sortIndex?: number;
};

export type LocationForDetailsCard = {
  id: string;
  name: string;
  slug: string;
  dist?: number;
  specialties: Lookup<SpecialtyForLocationDetailsCard>;
  address: {
    firstLine: string;
  };
};

type Props = GetOpenTimeResult & {
  location: LocationForDetailsCard;
  hideButton?: boolean;
  isMapPopup?: boolean;
  isMobileList?: boolean;
  disableButton?: boolean;
  daysFromToday?: number;
  specialtyBoxMode?: boolean;
  soonestSlots?: SoonestSlotTimeByLocationAndSpecialty;
  buttonText?: string;
  condensed?: boolean;
};

const LocationDetailsCard: React.FC<Props> = ({
  location,
  timeString,
  timeBlock,
  isOpenNow,
  hideButton,
  isMapPopup,
  disableButton,
  specialtyBoxMode,
  soonestSlots,
  buttonText,
  condensed = "true",
}) => {
  const i18n = useTranslation();
  const {pathname} = useNextPagesPaths();

  const {name, dist, specialties, address} = location;

  const showDist = useMemo(() => typeof dist === "number" && dist > -1, [dist]);

  const onClick = useCallback(() => {
    analytics.post({
      category: "LocationDiscovery",
      action: "Clicked",
      label: "SelectClinic",
      extraData: {
        locationId: location.id,
        milesAway: location.dist,
        openNow: isOpenNow,
        isMapPopup,
        source: pathname === v5Pages.locations ? "index" : "footer",
      },
    });
  }, [isMapPopup, isOpenNow, location.dist, location.id, pathname]);

  const orderedSpecialties = useMemo(
    () =>
      specialties
        .vals()
        .filter(s => !s.isVirtual)
        .sortBy(s => s.sortIndex),
    [specialties],
  );

  const linkToLocationDetails = useMemo(
    () => createURLBuilder(v5Pages.clinicDetails).replaceSegment(location.slug).build(),
    [location.slug],
  );

  return (
    <div className="fs14 zIndex1 bg-transparent">
      <div className="df fdc jcsb pos-r hide-scroll-bars">
        <div className="mb2 lh12">
          <h3
            className={`font-csb-f fw5-f mb1 ${
              condensed ? "maxw18 maxw12-xs" : ""
            } fs18 minw18 minw0-xs font-isb`}
            data-cy="location-name"
          >
            {name}
          </h3>
          <div className="mt-1">
            <span className="font-isb">
              {timeBlock && (
                <strong className={`font-isb ${!isOpenNow ? "gray800-f" : "darkGreen-f mr1"}`}>
                  {isOpenNow ? i18n.t("Open") : null}
                </strong>
              )}
              <span className="gray600 font-ir">{timeString}</span>
            </span>
          </div>

          <div className="gray600 font-ir minh8 ls-3 pb2 flex fw maxw16-xs">
            <span>{address.firstLine}</span>
            {showDist ? <span> · {i18n.t("{{number}} mi away", {number: dist})}</span> : null}
          </div>
        </div>

        <div
          className="flex flex-col overflow-x-auto"
          style={{maxWidth: specialtyBoxMode ? "90vw" : "80vw"}}
        >
          <ul className={`mb-2 grid gap-2 ${specialtyBoxMode ? "gafc" : "gafr"}`}>
            {orderedSpecialties.map(s => (
              <li key={location.id + s.id}>
                <SpecialtyAndTime
                  location={location}
                  specialty={s}
                  boxMode={specialtyBoxMode}
                  soonestSlots={soonestSlots}
                />
              </li>
            ))}
            {specialtyBoxMode && <li className="minw2" aria-hidden />}
          </ul>

          <div className="flex">
            {hideButton ? null : disableButton ? (
              // For valid DOM nesting we need to use an element other than `a` (can't nest `a` inside `a`)
              <span
                className={getButtonStyle(ButtonVariant.FILL, ButtonSize.SM, ButtonShape.ROUND, "")}
              >
                {buttonText || i18n.t("View location")}
              </span>
            ) : (
              <Button onClick={onClick} href={linkToLocationDetails} size={ButtonSize.SM}>
                {buttonText || i18n.t("View location")}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(LocationDetailsCard);
