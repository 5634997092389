import {colors} from "@c10h/colors";
import Circle from "@components/Circle";
import Fade from "@components/Transitions/Fade";
import {useTranslation} from "@i18n/client";
import React from "react";

import {SpecialtyId} from "../../../constants/specialtyIds";
import {SoonestSlotTimeByLocationAndSpecialty} from "../../../hooks/useGetSoonestTime";
import {useSpecialtyConfig} from "../../../hooks/useLocalSpecialtyConfigs";
import {useReadableSlotTime} from "../../../hooks/useReadableSlotTime";
import {ReadableSlotTime} from "../ReadableSlotTime";

type LocationForSpecialtyAndTime = {
  id: string;
  name: string;
};

export type SpecialtyForSpecialtyAndTime = {
  id: string;
  name: string;
};

type Props = {
  location: LocationForSpecialtyAndTime;
  specialty: SpecialtyForSpecialtyAndTime;
  boxMode?: boolean;
  soonestSlots?: SoonestSlotTimeByLocationAndSpecialty;
};

const SpecialtyAndTime: React.FC<Props> = React.memo(
  ({location, specialty, boxMode, soonestSlots}) => {
    const i18n = useTranslation();

    // @ts-expect-error TS2532: Object is possibly 'undefined'.
    const isLoaded = !!soonestSlots[location.id];

    // @ts-expect-error TS2532: Object is possibly 'undefined'.
    const firstSlot = isLoaded && soonestSlots[location.id][specialty.id];

    const isPrimaryCare = specialty.id === SpecialtyId.PRIMARY_CARE;
    const {nameOverride} = useSpecialtyConfig(specialty.id);

    const showPrimaryCareWait = isPrimaryCare && isLoaded && !firstSlot;
    const time = useReadableSlotTime(firstSlot);

    return boxMode ? (
      <div className="bg-gray100 p3 br3 wfc por-r font-ir fs14 gray600 ls-3">
        <div className="df fdc wsnw">
          <span className="font-ib gray800 fs14">{nameOverride || specialty.name}</span>
          <Fade in={isLoaded} timeout={0} unmountOnExit>
            <div className="df aic mt1">
              <Circle
                diameter={5}
                color={showPrimaryCareWait || !firstSlot ? colors.red : colors.darkGreen}
                className="mr1"
              />
              {showPrimaryCareWait ? (
                <span>{i18n.t("One week wait")}</span>
              ) : firstSlot ? (
                <span>
                  <ReadableSlotTime time={firstSlot} dateOnlyAfterTomorrow />
                </span>
              ) : isLoaded ? (
                i18n.t("Limited availability")
              ) : null}
            </div>
          </Fade>
        </div>
      </div>
    ) : (
      <div className="font-ir gray600 df aic wsnw fw ml2">
        <div className="df aic pos-r">
          <Circle
            diameter={5}
            color={showPrimaryCareWait || (!firstSlot && isLoaded) ? colors.red : colors.darkGreen}
            className="mr1 pos-a"
            style={{left: -8}}
          />
          <span className="font-ib gray800 wsnw">{nameOverride || specialty.name}</span>
          {isLoaded && (
            <span className="fs10 mh1" aria-hidden>
              •
            </span>
          )}
        </div>
        <Fade in={isLoaded} timeout={0} unmountOnExit>
          <div className="df aic">
            {showPrimaryCareWait ? (
              <span className="wsnw">{i18n.t("One week wait")}</span>
            ) : firstSlot ? (
              <span className="wsnw">{i18n.t("Next: {{time}}", {time})}</span>
            ) : isLoaded ? (
              <span className="wsnw">{i18n.t("Limited availability")}</span>
            ) : null}
          </div>
        </Fade>
      </div>
    );
  },
);

SpecialtyAndTime.displayName = "SpecialtyAndTime";

export default SpecialtyAndTime;
