import {useTranslation} from "@i18n/client";

import {SpecialtyId} from "../constants/specialtyIds";

type LocalSpecialtyConfig = {
  icon: string;
  nameOverride?: string;
};

export const useLocalSpecialtyConfigs = (): Partial<Record<SpecialtyId, LocalSpecialtyConfig>> => {
  const i18n = useTranslation();

  return {
    [SpecialtyId.PRIMARY_CARE]: {
      icon: "stethoscope-2",
    },
    [SpecialtyId.URGENT_CARE]: {
      icon: "shield-cross",
    },
    [SpecialtyId.VIRTUAL_URGENT_CARE]: {
      icon: "happy-phone-in-hand",
    },
    [SpecialtyId.VIRTUAL_PRIMARY_CARE]: {
      icon: "happy-phone-in-hand",
    },
    [SpecialtyId.COVID_TESTING_MOBILE]: {
      icon: "virus-molecule",
      nameOverride: i18n.t("COVID Testing"),
    },
    [SpecialtyId.COVID_TRAVEL_CLEARANCE]: {
      icon: "plane",
      nameOverride: i18n.t("COVID Travel Testing"),
    },
  };
};

export const useSpecialtyConfig = (specialtyId: string): LocalSpecialtyConfig =>
  // @ts-expect-error TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Partial<Record<SpecialtyId, LocalSpecialtyConfig>>'.
  useLocalSpecialtyConfigs()[specialtyId] || {icon: "stethoscope-2"};
