import {MonolithLocationFields} from "@services/graphql/queries/location";

export const maybeGetLocationWithSpecialtyHours = (
  location: MonolithLocationFields,
  specialtyId?: string,
) => {
  const {locationSpecialties} = location;

  const specialtyHours = locationSpecialties?.find(s => s?.specialtyId === specialtyId)?.hours;
  return (
    (specialtyId &&
      specialtyHours && {
        ...location,
        specialties: {
          [specialtyId]: {
            hours: specialtyHours,
          },
        },
      }) ||
    location
  );
};
