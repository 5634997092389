import {colors} from "@c10h/colors";
import Link from "next/link";
import {useRouter} from "next/router";
import {useTranslation} from "@i18n/client";
import React, {memo} from "react";
import {RootStateLocation} from "src/store/types";
import {analytics} from "src/utils/analytics";
import {getOpenTime} from "src/utils/timeUtils";

import {SoonestSlotTimeByLocationAndSpecialty} from "../../../hooks/useGetSoonestTime";
import {useIOSScroller} from "../../../hooks/useIOSScroller";
import {I18nNamespace} from "@i18n";
import LocationDetailsCard from "./LocationDetailsCard";
import {v5Pages} from "../../_common/_constants";

type Props = {
  locations: RootStateLocation[];
  soonestSlots: SoonestSlotTimeByLocationAndSpecialty;
};

const ClinicList: React.FC<Props> = ({locations, soonestSlots}) => {
  const {
    query: {specialtyId},
  } = useRouter();

  const i18nDB = useTranslation(I18nNamespace.DB);
  const scrollRef = useIOSScroller(locations);

  return (
    <div
      className="pos-a zIndex1 ovf-y-a ovf-x-h bg-white pt14 pb30 overscroll-contain"
      data-cy="clinics-list"
      ref={scrollRef}
    >
      <ul className="dg gafr gg2" data-cy="locations-list">
        {locations.map(l => {
          const {timeString, timeBlock, isOpenNow, isBeforeOpeningToday, daysFromToday} =
            getOpenTime(i18nDB, l);
          const firstSlot = soonestSlots[l.id]?.vals().min();

          const onClick = () => {
            analytics.post({
              category: analytics.category.LOCATION_DISCOVERY,
              label: analytics.label.SELECT_CLINIC,
              action: analytics.action.CLICKED,
              extraData: {
                locationId: l.id,
                milesAway: l.dist,
                openNow: isOpenNow,
                nextAvailable: firstSlot,
                isMapPopup: false,
                source: "index",
              },
            });
          };

          return (
            <li
              key={`ClinicList-${l.id}`}
              style={{
                "--br": 0.1,
                borderBottom: `1px solid ${colors.gray100}`,
                maxWidth: "100vw",
              }}
            >
              <Link
                href={{
                  pathname: v5Pages.clinicDetails,
                  query: {slug: l.slug, ...(specialtyId ? {specialtyId} : {})},
                }}
                className="focus-bsDarkBlue3-hug df aic hover-gray800 m4 p0"
                onClick={onClick}
              >
                <LocationDetailsCard
                  location={l}
                  timeBlock={timeBlock}
                  timeString={timeString}
                  isOpenNow={isOpenNow}
                  isBeforeOpeningToday={isBeforeOpeningToday}
                  hideButton
                  daysFromToday={daysFromToday}
                  specialtyBoxMode
                  soonestSlots={soonestSlots}
                />
                <span className="pos-a right0 m0 top0 bottom0 ma df aic zIndex2 bg-transparent">
                  <span className="cIcon-arr2Right gray800 fs20" aria-hidden />
                </span>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default memo(ClinicList);
