import {TreeItem} from "@components/components/Tree/ComplexTreeWrapper";
import TreeOverlay from "@components/components/Tree/TreeOverlay";
import {ButtonVariant} from "@components/components/Tree/types";
import {TextInput} from "@components/Input";
import React, {useCallback, useEffect, useMemo, useState} from "react";

import ComplexTreeWrapper from "./ComplexTreeWrapper";
import {Url} from "next/dist/shared/lib/router/router";
import {LinkProps} from "next/link";

interface TreeViewProps {
  selected?: TreeItem;
  initialExpandedItems: string[];
  selectorName: string;
  searchLabel: string;
  searchPlaceholder: string;
  buildTree: (searchTerm: string) => TreeItem<string>;
  handleSelect: (id: string | number) => unknown;
  handleSearch?: (searchTerm?: string, tree?: TreeItem<string>) => void;
  buttonVariant: ButtonVariant;
  getHref?: (item: TreeItem<string>) => Url | undefined;
  getAs?: (item: TreeItem<string>) => LinkProps["as"] | undefined;
  shallow?: LinkProps["shallow"];
}

export const TreeView: React.FC<TreeViewProps> = ({
  buildTree,
  initialExpandedItems,
  selected,
  selectorName,
  searchLabel,
  searchPlaceholder,
  handleSelect,
  handleSearch,
  buttonVariant,
  getHref,
  getAs,
  shallow,
}) => {
  const [searchTerm, setSearch] = useState("");

  const tree = useMemo(() => buildTree(searchTerm), [buildTree, searchTerm]);
  const handleClose = useCallback(() => setSearch(""), []);

  const handleTreeItemSelect = (close: () => void) => (item: TreeItem<string>) => {
    handleSelect(item.id);
    close();
  };

  useEffect(() => {
    if (handleSearch) handleSearch(searchTerm, tree);
  }, [handleSearch, searchTerm, tree]);

  return (
    <TreeOverlay
      selectedItem={typeof selected?.data === "string" ? selected?.data : ""}
      selectorName={selectorName}
      onClose={handleClose}
      variant={buttonVariant}
      renderChildren={close => (
        <div className="bg-white br5 bs2 p-6 contrast-tb">
          <TextInput
            onChange={setSearch}
            icon="search"
            placeholder={searchPlaceholder}
            ariaLabel={searchLabel}
            autoFocus
            autoComplete="off"
            inputMode="text"
          />
          <ComplexTreeWrapper
            onSelect={handleTreeItemSelect(close)}
            tree={tree}
            defaultExpandedItems={initialExpandedItems}
            selectedItemId={selected?.id || ""}
            getHref={getHref}
            getAs={getAs}
            shallow={shallow}
          />
        </div>
      )}
    />
  );
};
export default React.memo(TreeView);
