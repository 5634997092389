import moment, {MomentInput} from "moment-timezone";
import {useTranslation} from "@i18n/client";
import React, {memo, useEffect, useState} from "react";

import {isEvenHour} from "../../hooks/useReadableSlotTime";

// TODO: Refactor to use hook
const InternalReadableSlotTime = ({
  time,
  lowerCase,
  dateOnlyAfterTomorrow,
}: {
  time: MomentInput;
  lowerCase?: boolean;
  dateOnlyAfterTomorrow?: boolean;
}): React.ReactElement => {
  const i18n = useTranslation();
  const [slotTime, setSlotTime] = useState(null);

  useEffect(() => {
    if (!time) return;
    const actualMoment = moment(time);
    const timeFormat = isEvenHour(actualMoment.valueOf()) ? "ha" : "h:mma";
    const t = actualMoment.calendar(null, {
      sameDay: `[${lowerCase ? i18n.t("today") : i18n.t("Today")}] ${timeFormat}`,
      nextDay: `[${lowerCase ? i18n.t("tomorrow") : i18n.t("Tomorrow")}] ${timeFormat}`,
      lastWeek: `[${lowerCase ? i18n.t("last") : i18n.t("Last")}] dddd`,
      nextWeek: dateOnlyAfterTomorrow ? "MMM Do" : `ddd ${timeFormat}`,
      sameElse: dateOnlyAfterTomorrow ? "MMM Do" : "ddd, MMM Do",
    });

    // @ts-expect-error TS2345: Argument of type 'string' is not assignable to parameter of type 'SetStateAction<null>'.
    setSlotTime(t);
  }, [time]);

  // @ts-expect-error TS2322: Type 'null' is not assignable to type 'ReactElement<any, string | JSXElementConstructor<any>>'.
  return slotTime;
};

export const ReadableSlotTime = memo(InternalReadableSlotTime);
