import moment from "moment";
import {useTranslation} from "@i18n/client";
import {useMemo} from "react";

import {MsMap} from "../constants/MsMap";
import {I18nNamespace} from "@i18n";
import getTimezone from "../utils/getTimezone";
import {TranslateFn} from "@i18n";

export const isEvenHour = (msTime: number): boolean => msTime % MsMap.ONE_HOUR === 0;

type ReadableTimeOptions = {
  withNext?: boolean;
  isLowerCase?: boolean;
  withoutHour?: boolean;
  timezone?: string;
};

export const getReadableSlotTime = ({
  time,
  t,
  timezone,
  isLowerCase = false,
  withNext = false,
  withoutHour = false,
}: {
  time?: number;
  t: TranslateFn;
  timezone?: string;
} & ReadableTimeOptions): string | null => {
  if (!time) return null;
  const sameDay = `${t("Today", {ns: "website-db"})}`;
  const nextDay = `${t("Tomorrow", {ns: "website-db"})}`;
  const lastWeek = `${t("Last", {ns: "website-db"})}`;
  const actualMoment = moment(time).tz(timezone || getTimezone());
  const timeFormat = isEvenHour(actualMoment.valueOf()) ? "ha" : "h:mma";

  const formatWithHour = {
    sameDay: `[${sameDay}] · ${timeFormat}`,
    nextDay: `[${nextDay}] · ${timeFormat}`,
    nextWeek: `MMM D · ${timeFormat}`,
    lastWeek: `[${lastWeek}] · dddd`,
    sameElse: `MMM D · ${timeFormat}`,
  };
  const formatWithoutHour = {
    sameDay: `[${sameDay}], MMMM D`,
    nextDay: `[${nextDay}], MMMM D`,
    nextWeek: `dddd, MMMM D`,
    lastWeek: `[${lastWeek}] dddd`,
    sameElse: `dddd, MMMM D`,
  };

  const formattedTime = actualMoment.calendar(
    null,
    withoutHour ? formatWithoutHour : formatWithHour,
  );

  const maybeNext = withNext ? t("Next: ") : "";
  const maybeLowerCased = isLowerCase ? formattedTime.toLowerCase() : formattedTime;
  const final = `${maybeNext}${maybeLowerCased}`;

  return final;
};

export const useReadableSlotTime = (
  time?: number,
  {isLowerCase, withNext, withoutHour, timezone}: ReadableTimeOptions = {
    isLowerCase: false,
    withNext: false,
    withoutHour: false,
  },
): string | null => {
  const {t} = useTranslation(I18nNamespace.DB);
  return useMemo(
    () => getReadableSlotTime({time, t, timezone, isLowerCase, withNext, withoutHour}),
    [time, t, timezone, isLowerCase, withNext, withoutHour],
  );
};
